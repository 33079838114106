import { FC, useEffect, useRef, useState } from 'react'
import styles from './ProductBreakdown.module.scss'
import { MediaItem, ProductBreakdownItem, ProductBreakdownProps } from './ProductBreakdown.types'
import { Button } from 'components/Phantom/Button'
import { VideoGif } from 'components/basic/VideoGif'
import { Img } from 'components/basic/Img'
import { generateID } from 'components/_utils/idUtils'
import { useSwipe } from 'components/_hooks/useSwipe'
import { Icon } from 'components/Phantom/Icon'
import { observer } from 'mobx-react'
import { useRootStore } from 'components/_hooks/useRootStore'
import { amSeenProductBreakdown } from 'events/amplitude'

export const ProductBreakdownConnected = observer((props: ProductBreakdownProps) => {
	const { header, cta, items } = props

	const { settingsStore } = useRootStore()
	const metric = settingsStore.metricRegion

	return (
		<ProductBreakdown
			header={header}
			cta={cta}
			items={items}
			metric={metric}
		/>
	)
})

export const ProductBreakdown: FC<ProductBreakdownProps> = (props) => {
	const { header = 'Add the smart system to any bed', cta = { text: 'Shop now', href: '/product/pod-cover' }, items = getDefaultItems(props.metric) } = props

	const [selectedIndex, setSelectedIndex] = useState(0)
	const wrapperRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const handleFullIntersection = (entries: IntersectionObserverEntry[]) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting && !window.sessionStorage.getItem('seen_product_breakdown')) {
					window.sessionStorage.setItem('seen_product_breakdown', 'true')
					amSeenProductBreakdown()
					console.log('seen product breakdown')
				}
			})
		}

		const observer = new IntersectionObserver(handleFullIntersection)
		observer.observe(wrapperRef.current)

		return () => {
			observer.disconnect()
		}
	}, [])

	return (
		<section
			className={styles.wrapper}
			ref={wrapperRef}
		>
			<div className={styles.container}>
				<h2>{header}</h2>
				<div className={styles.breakdown}>
					<ul className={styles.breakdown_list}>
						{items.map((item, i) => (
							<li
								key={item.title}
								data-highlighted={i === selectedIndex}
								className={styles.breakdown_item}
							>
								<Button.Empty
									id={`product-breakdown-item-${generateID(item.title)}`}
									className={styles.item_button}
									onClick={() => setSelectedIndex(i)}
								>
									<BreakdownItem
										{...item}
										selected={i === selectedIndex}
									/>
								</Button.Empty>
							</li>
						))}
					</ul>
					<div className={styles.cta}>
						<Button.White
							id={'product-breakdown-cta'}
							href={cta.href}
						>
							{cta.text}
						</Button.White>
					</div>
				</div>
				<div className={styles.media_container}>
					{items.map((item, i) => (
						<div
							key={`product-breakdown-media-${i}`}
							className={styles.media_stack}
							data-highlighted={i === selectedIndex}
						>
							<MediaStack {...item} />
						</div>
					))}
				</div>
			</div>
		</section>
	)
}

const BreakdownItem: FC<ProductBreakdownItem & { selected: boolean }> = (props) => {
	const descriptionRef = useRef<HTMLSpanElement>(null)

	const [height, setHeight] = useState(0)

	useEffect(() => {
		const handleResize = () => {
			if (descriptionRef.current) {
				setHeight(descriptionRef.current.getBoundingClientRect().height)
			}
		}
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	useEffect(() => {
		if (descriptionRef.current) {
			setHeight(descriptionRef.current.getBoundingClientRect().height)
		}
	}, [props.selected])

	return (
		<span className={styles.breakdown_item_content}>
			<h3>{props.title}</h3>
			<p
				style={{
					maxHeight: props.selected ? `calc(${height}px + 0.5em)` : '0px',
					marginTop: props.selected ? `0.5rem` : '0px',
				}}
			>
				<span ref={descriptionRef}>{props.description}</span>
			</p>
		</span>
	)
}

const MediaStack: FC<ProductBreakdownItem> = (props) => {
	const { media, title } = props
	const [currentIndex, setCurrentIndex] = useState(0)
	const sliderRef = useRef<HTMLUListElement>(null)

	useSwipe(
		sliderRef,
		(direction: 'left' | 'right') => {
			if (direction === 'left') {
				setCurrentIndex((prev) => Math.min(media.length - 1, prev + 1))
			} else if (direction === 'right') {
				setCurrentIndex((prev) => Math.max(0, prev - 1))
			}
		},
		50,
		500
	)

	return (
		<>
			<ul
				className={styles.media}
				ref={sliderRef}
			>
				{media.map((media, i) => (
					<li
						key={`product-breakdown-media-${i}`}
						className={styles.media_item}
						data-highlighted={i === currentIndex}
					>
						<MediaRendered {...media} />
					</li>
				))}
			</ul>

			{currentIndex > 0 && (
				<Button.Empty
					id={'product-breakdown-prev'}
					className={styles.media_control_prev}
					onClick={() => setCurrentIndex((prev) => Math.max(0, prev - 1))}
				>
					<Icon
						name={'ChevronLeftDark'}
						color={'white'}
						size={24}
					/>
				</Button.Empty>
			)}

			{media.length > 1 && currentIndex < media.length - 1 && (
				<Button.Empty
					id={'product-breakdown-next'}
					className={styles.media_control_next}
					onClick={() => setCurrentIndex((prev) => Math.min(media.length - 1, prev + 1))}
				>
					<Icon
						name={'ChevronRightDark'}
						color={'white'}
						size={24}
					/>
				</Button.Empty>
			)}

			{media.length > 1 && (
				<ul className={styles.dots}>
					{media.map((_, i) => (
						<li
							key={`product-breakdown-dot-${i}`}
							data-highlighted={i === currentIndex}
						>
							<Button.Empty
								id={`product-breakdown-dot-${generateID(title)}-${i}}`}
								className={styles.dot}
								onClick={() => setCurrentIndex(i)}
							>
								<div className={styles.dot_inner} />
							</Button.Empty>
						</li>
					))}
				</ul>
			)}
		</>
	)
}

const MediaRendered: FC<MediaItem> = (props) => {
	switch (props.type) {
		case 'image':
			return (
				<Img
					src={props.data.src}
					alt={props.data.alt}
					className={styles.media_image}
				/>
			)
		case 'video-gif':
			return (
				<VideoGif
					src={props.data.src}
					className={styles.media_video}
				/>
			)
	}
}

export const getDefaultItems = (metric: boolean): ProductBreakdownItem[] => {
	return [
		{
			title: 'The Cover',
			description:
				'A high-tech layer that wraps around your mattress, similar to a fitted sheet. It facilitates automatic heating and cooling of the Pod and integrates the imperceptible health-grade sensors that track your heart rate, breathing rate, while you sleep.',
			media: [
				{
					type: 'video-gif',
					data: {
						src: 'https://d115sb6i1ixllw.cloudfront.net/assets/product_breakdown_cover_loop_3.mp4',
					},
				},
			],
		},
		{
			title: 'The Base',
			description:
				'An adjustable Base that works seamlessly with the Pod to automatically reduce snoring. The Base also offers custom positions for reading, relaxing, and sleeping. Only 3" thick, holds up to 750 lbs, and fits inside your existing bed frame, right under your mattress. Exclusive to Pod 4 Ultra.',
			media: [
				{
					type: 'image',
					data: {
						src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/product_breakdown_base_1.png',
						alt: 'The Eight Sleep Base',
					},
				},
				{
					type: 'image',
					data: {
						src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/product_breakdown_base_2.png',
						alt: 'The Eight Sleep Base',
					},
				},
				{
					type: 'image',
					data: {
						src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/product_breakdown_base_3.png',
						alt: 'The Eight Sleep Base',
					},
				},
			],
		},
		{
			title: 'The Hub',
			description: `The Hub is the central system of the Pod that holds the water for cooling and heating and also the connectivity to the app. It enables Autopilot to take actions in real-time. Only one Hub is needed per Pod and it measures ${
				metric ? '15cm W x 40cm L x 40cm D' : '6”W x 16”L x 16”D'
			}, fitting next to your nightstand or bed.`,
			media: [
				{
					type: 'image',
					data: {
						src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Still_05_full.png',
						alt: 'The Eight Sleep Hub',
					},
				},
				// {
				// 	type: 'image',
				// 	data: {
				// 		src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/product_breakdown_hub_2.png',
				// 		alt: 'The Eight Sleep Hub',
				// 	},
				// },
			],
		},
	]
}
